@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Noto Sans", sans-serif;
  color: #333;
  max-width: 100vw;
  overflow-x: hidden;
}
